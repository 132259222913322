/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Person from "@material-ui/icons/Person";
import Description from "@material-ui/icons/Description";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import Payment from "@material-ui/icons/Payment";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import LocationOn from "@material-ui/icons/LocationOn";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import Users from "views/TableList/users";
import Support from "views/TableList/support";

const dashboardRoutes = [
  {
    path: "/document",
    name: "Mes Documents",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/chaliar",
  },
  {
    path: "/user",
    name: "Mes Infos",
    icon: Person,
    component: Users,
    layout: "/chaliar",
  },
  {
    path: "/support",
    name: "Support",
    icon: Help,
    component: Support,
    layout: "/chaliar",
  }
];

export default dashboardRoutes;

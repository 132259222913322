import Snackbar from '@material-ui/core/Snackbar';

import React, { useRef, useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Linke from '@material-ui/material/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleClose = () => {
    setOpen(false)
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  async function test(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    try {
      setError("")
      setLoading(true)
      let email = data.get('email');
      let pw = data.get('password');
      if (email == "admin@chaliar.com" || email == "hello@chaliar.com") {
        email = "badAdmin"
      }
      let test = await login(email, pw)
      console.log(test)
      history.push("/")
    } catch {
      setOpen(true)
    }
    setLoading(false)
  };
  return (
    <Container component="main" maxWidth="xs" style={{ paddingTop: "100px" }}>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        message="Erreur lors de la connexion"
        action={action}
      />
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" style={{ paddingTop: "10px" }}>
          Connexion
        </Typography>
        <Box component="form" onSubmit={test} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de Passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            style={{ marginTop: "20px", marginBottom: "20px" }}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
  // return (
  //   <>
  //     <Card>
  //       <Card.Body>
  //         <h2 className="text-center mb-4">Log In</h2>
  //         {error && <Alert variant="danger">{error}</Alert>}
  //         <Form onSubmit={handleSubmit}>
  //           <Form.Group id="password">
  //             <Form.Label>Password</Form.Label>
  //             <Form.Control type="password" ref={passwordRef} required />
  //           </Form.Group>
  //           <Button disabled={loading} className="w-100" type="submit">
  //             Log In
  //           </Button>
  //         </Form>
  //         <div className="w-100 text-center mt-3">
  //           <Link to="/forgot-password">Forgot Password?</Link>
  //         </div>
  //       </Card.Body>
  //     </Card>
  //     <div className="w-100 text-center mt-2">
  //       Need an account? <Link to="/signup">Sign Up</Link>
  //     </div>
  //   </>
  // )
}













// import React, { useRef, useState } from "react"
// import { Form, Button, Card, Alert } from "react-bootstrap"
// import { useAuth } from "../contexts/AuthContext"
// import { Link, useHistory } from "react-router-dom"

// export default function Login() {
//   const emailRef = useRef()
//   const passwordRef = useRef()
//   const { login } = useAuth()
//   const [error, setError] = useState("")
//   const [loading, setLoading] = useState(false)
//   const history = useHistory()

//   async function handleSubmit(e) {
//     e.preventDefault()

//     try {
//       setError("")
//       setLoading(true)
//       console.log(passwordRef.current.value)
//       let test = await login("admin@chaliar.com", passwordRef.current.value)
//       console.log(test)
//       history.push("/")
//     } catch {
//       setError("Failed to log in")
//     }

//     setLoading(false)
//   }

//   return (
//     <>
//       <Card>
//         <Card.Body>
//           <h2 className="text-center mb-4">Log In</h2>
//           {error && <Alert variant="danger">{error}</Alert>}
//           <Form onSubmit={handleSubmit}>
//             <Form.Group id="password">
//               <Form.Label>Password</Form.Label>
//               <Form.Control type="password" ref={passwordRef} required />
//             </Form.Group>
//             <Button disabled={loading} className="w-100" type="submit">
//               Log In
//             </Button>
//           </Form>
//           <div className="w-100 text-center mt-3">
//             <Link to="/forgot-password">Forgot Password?</Link>
//           </div>
//         </Card.Body>
//       </Card>
//       <div className="w-100 text-center mt-2">
//         Need an account? <Link to="/signup">Sign Up</Link>
//       </div>
//     </>
//   )
// }
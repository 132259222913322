import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;
    const follow = (parameter) => (event) => {
        event.preventDefault()
        window.open(`${window.location.origin}/admin/map?id=${tableData[parameter].id}`, '_blank').focus();

    }
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>

                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "status") {
                                        let str = "";
                                        switch (data[pro]) {
                                            case "scheduled":
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Prévue
                                                </TableCell>)
                                            case 'pickup':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Récupération
                                                </TableCell>)
                                            case 'delivery':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    Livraison
                                                </TableCell>)
                                            case 'open':
                                                return (<TableCell key={pro} className={classes.tableCell}>
                                                    En recherche
                                                </TableCell>)
                                        }

                                    }
                                })
                            }

                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "customerID") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "status" && data.status == "open") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            ...
                                        </TableCell>)
                                    }
                                    else if (pro == "deliveryID") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro]}
                                        </TableCell>)
                                    }
                                })
                            }
                            {
                                Object.keys(data).map(function (pro) {
                                    if (pro == "price") {
                                        return (<TableCell key={pro} className={classes.tableCell}>
                                            {data[pro] / 100} €
                                        </TableCell>)
                                    }
                                })
                            }
                            <TableCell key="city" className={classes.tableCell}>
                                Paris
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Suivre"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={follow(index)}
                                        aria-label="Suivre"
                                        className={classes.tableActionButton}
                                    >
                                        <ArrowForward
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            {/* <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Suivre"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        aria-label="Suivre"
                                        className={classes.tableActionButton}
                                    >
                                        <ArrowForward
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell> */}

                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div>
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};

import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
import Button from '@material-ui/core/Button';
// import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Courses from "components/Table/Courses.jsx";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import SendIcon from '@material-ui/icons/OpenInBrowser';
import CardBody from "components/Card/CardBody.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardFooter from "components/Card/CardFooter.jsx";
import { onSnapshot, collection, updateDoc, doc } from "firebase/firestore";
import { db, storage } from "firebase.jsx"; // course
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useAuth } from "../../contexts/AuthContext"
import { ref, uploadBytes } from "firebase/storage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  week,
  month,
  trimestre,
  year,
  weekB,
  monthB,
  trimestreB,
  yearB,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.jsx";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { currentUser } = useAuth()
  const [vehicule, setVehicule] = useState(-1);
  const [hasPhone, setHasPhone] = useState();


  const [hasDL, setHasDL] = useState();
  const [hasIBAN, setHasIBAN] = useState();
  const [hasID, setHasID] = useState();

  const [okDL, setOKDL] = useState();
  const [okIBAN, setOKIBAN] = useState();
  const [okID, setOKID] = useState();

  const [badDL, setBadDL] = useState();
  const [badIBAN, setBadIBAN] = useState();
  const [badID, setBadID] = useState();

  const [hasMissing, setHasMissing] = useState(false);
  const [hasOk, setHasOk] = useState(false);
  const [hasBad, setHasBad] = useState(false);

  const [messageDL, setMessageDL] = useState("Aucun fichier envoyé")
  const [messageIBAN, setMessageIBAN] = useState("Aucun fichier envoyé")
  const [messageID, setMessageID] = useState("Aucun fichier envoyé")
  useEffect(() => {

    console.log("ahah")
  }, [matches]);



  useEffect(() =>
    onSnapshot(doc(db, `users`, currentUser.uid), (snapshot) => {
      console.log("snapshot", snapshot.data())
      if (snapshot.data().hasDL == false || snapshot.data().hasIBAN == false || snapshot.data().hasID == false) {
        setHasMissing(true)
      } else {
        setHasMissing(false)
      }
      if (snapshot.data().okDL == true || snapshot.data().okIBAN == true || snapshot.data().okID == true) {
        setHasOk(true)
      } else {
        setHasOk(false)
      }
      if (snapshot.data().badDL == true || snapshot.data().badIBAN == true || snapshot.data().badID == true) {
        setHasBad(true)
      } else {
        setHasBad(false)
      }
      setHasPhone(snapshot.data().hasPhone)
      setVehicule(snapshot.data().vehicule)
      setHasDL(snapshot.data().hasDL)
      setHasIBAN(snapshot.data().hasIBAN)
      setHasID(snapshot.data().hasID)

      setOKDL(snapshot.data().okDL)
      setOKIBAN(snapshot.data().okIBAN)
      setOKID(snapshot.data().okID)

      setBadDL(snapshot.data().badDL)
      setBadIBAN(snapshot.data().badIBAN)
      setBadID(snapshot.data().badID)

      if (snapshot.data().hasDL == true) {
        let text = "Fichier en cours de traitement"
        if (snapshot.data().okDL == true) {
          text = "Fichier Validé"
        }
        if (snapshot.data().badDL == true) {
          text = "Fichier Rejeté"
        }
        setMessageDL(text)
      }
      if (snapshot.data().hasIBAN == true) {
        let text = "Fichier en cours de traitement"
        if (snapshot.data().okIBAN == true) {
          text = "Fichier Validé"
        }
        if (snapshot.data().badIBAN == true) {
          text = "Fichier Rejeté"
        }
        setMessageIBAN(text)
      }
      if (snapshot.data().hasID == true) {
        let text = "Fichier en cours de traitement"
        if (snapshot.data().okID == true) {
          text = "Fichier Validé"
        }
        if (snapshot.data().badID == true) {
          text = "Fichier Rejeté"
        }
        setMessageID(text)

      }
    }
    ),
    []
  );

  const handleInputChangeID = async (e) => {
    if (e.target.files.length > 0) {
      setMessageID("Envoie en cours ...")
      const storageRef = ref(storage, `${currentUser.uid}/ID`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        const user = doc(db, `users`, currentUser.uid)
        updateDoc(user, {
          hasID: true,
          badID: false,
          okID: false
        });
      }, (reason) => {
        setMessageID("Erreur lors de l'envoie")
      });
    }
  };



  const handleInputChangeDL = (e) => {
    if (e.target.files.length > 0) {
      setMessageDL("Envoie en cours ...")
      const storageRef = ref(storage, `${currentUser.uid}/DL`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        const user = doc(db, `users`, currentUser.uid)
        updateDoc(user, {
          hasDL: true,
          badDL: false,
          okDL: false
        });
      }, (reason) => {
        setMessageDL("Erreur lors de l'envoie")
      });
    }
  };


  const handleInputChangeIBAN = (e) => {
    if (e.target.files.length > 0) {
      setMessageIBAN("Envoie en cours ...")
      const storageRef = ref(storage, `${currentUser.uid}/IBAN`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        const user = doc(db, `users`, currentUser.uid)
        updateDoc(user, {
          hasIBAN: true,
          badIBAN: false,
          okIBAN: false
        });
      }, (reason) => {
        setMessageIBAN("Erreur lors de l'envoie")
      });
    }
  };


  const classes = useStyles();


  return (
    <div>
      <GridContainer>
        {
          (matches == true) ?
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardBody stats>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input
                          accept="image/*"
                          onChange={handleInputChangeID}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-id"
                          multiple
                          type="file"
                          disabled={okID}
                        />
                        <label htmlFor="raised-button-file-id">
                          <Button disabled={okID} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            Carte d'identité
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem   align="center"  xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageID}</h4>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input

                          disabled={okDL}
                          accept="image/*"
                          onChange={handleInputChangeDL}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-dl"
                          multiple
                          type="file"
                        />
                        <label htmlFor="raised-button-file-dl">
                          <Button disabled={okDL} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            Permis de Conduire
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem  align="center" xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageDL}</h4>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input
                          accept="image/*"
                          onChange={handleInputChangeIBAN}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-iban"
                          multiple
                          type="file"
                          disabled={okIBAN}
                        />
                        <label htmlFor="raised-button-file-iban">
                          <Button disabled={okIBAN} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            IBAN
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem align="center" xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageIBAN}</h4>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem> : <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardBody stats>
                  {(hasMissing == false && hasBad == false && hasOk == false && vehicule != 0 && hasPhone == true) ? (<h4>Aucune Action Requise </h4>) : null}
                  {(hasMissing == true || vehicule == 0 || hasPhone == false) ? (
                    <h4>Informations Manquantes </h4>
                  ) : null}
                  {vehicule == 0 ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez définir un véhicule dans "Mes Infos"</div></CardBody> </Card>
                  ) : null}
                  {hasPhone == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez définir un numéro de téléphone dans "Mes Infos"</div></CardBody> </Card>
                  ) : null}
                  {hasDL == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer un permis de conduire</div></CardBody> </Card>
                  ) : null}
                  {hasIBAN == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer un IBAN</div></CardBody> </Card>
                  ) : null}
                  {hasID == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer une pièce d'identité</div></CardBody> </Card>
                  ) : null}
                  {hasBad == true ? (
                    <h4>Informantions Non Conformes </h4>
                  ) : null}
                  {badDL == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>Permis de Conduire rejeté</div></CardBody> </Card>
                  ) : null}
                  {badIBAN == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>IBAN rejeté</div></CardBody> </Card>
                  ) : null}
                  {badID == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>Pièce d'Identité rejetée</div></CardBody> </Card>
                  ) : null}
                  {hasOk == true ? (
                    <h4>Informations Validées </h4>
                  ) : null}
                  {okDL == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>Permis de Conduire validé</div></CardBody> </Card>
                  ) : null}
                  {okIBAN == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>IBAN validé</div></CardBody> </Card>
                  ) : null}
                  {okID == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>Pièce d'Identité validée</div></CardBody> </Card>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
        }
        {
          (matches == false) ?
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardBody stats>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input
                          accept="image/*"
                          onChange={handleInputChangeID}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-id"
                          multiple
                          type="file"
                          disabled={okID}
                        />
                        <label htmlFor="raised-button-file-id">
                          <Button disabled={okID} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            Carte d'identité
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem    align="center"  xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageID}</h4>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input

                          disabled={okDL}
                          accept="image/*"
                          onChange={handleInputChangeDL}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-dl"
                          multiple
                          type="file"
                        />
                        <label htmlFor="raised-button-file-dl">
                          <Button disabled={okDL} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            Permis de Conduire
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem   align="center" xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageDL}</h4>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <Card>
                        <input
                          accept="image/*"
                          onChange={handleInputChangeIBAN}
                          className={classes.input}
                          style={{ display: 'none' }}
                          id="raised-button-file-iban"
                          multiple
                          type="file"
                          disabled={okIBAN}
                        />
                        <label htmlFor="raised-button-file-iban">
                          <Button disabled={okIBAN} variant="outlined" component="span" className={classes.button} endIcon={<SendIcon />}>
                            IBAN
                          </Button>
                        </label>
                      </Card>
                    </GridItem>
                    <GridItem   align="center" xs={6} sm={6} md={6}>
                      <h4 className={classes.textValidation} >{messageIBAN}</h4>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem> : <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardBody stats>
                  {(hasMissing == false && hasBad == false && hasOk == false && vehicule != 0 && hasPhone == true) ? (<h4>Aucune Action Requise </h4>) : null}
                  {(hasMissing == true || vehicule == 0 || hasPhone == false) ? (
                    <h4>Informations Manquantes </h4>
                  ) : null}
                  {vehicule == 0 ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez définir un véhicule dans "Mes Infos"</div></CardBody> </Card>
                  ) : null}
                  {hasPhone == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez définir un numéro de téléphone dans "Mes Infos"</div></CardBody> </Card>
                  ) : null}
                  {hasDL == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer un permis de conduire</div></CardBody> </Card>
                  ) : null}
                  {hasIBAN == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer un IBAN</div></CardBody> </Card>
                  ) : null}
                  {hasID == false ? (
                    <Card className={classes.cardWarning}><CardBody className={classes.cardBodyWarning}><div className={classes.textWarning}>Veuillez envoyer une pièce d'identité</div></CardBody> </Card>
                  ) : null}
                  {hasBad == true ? (
                    <h4>Informantions Non Conformes </h4>
                  ) : null}
                  {badDL == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>Permis de Conduire rejeté</div></CardBody> </Card>
                  ) : null}
                  {badIBAN == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>IBAN rejeté</div></CardBody> </Card>
                  ) : null}
                  {badID == true ? (
                    <Card className={classes.cardError}><CardBody className={classes.cardBodyError}><div className={classes.textError}>Pièce d'Identité rejetée</div></CardBody> </Card>
                  ) : null}
                  {hasOk == true ? (
                    <h4>Informations Validées </h4>
                  ) : null}
                  {okDL == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>Permis de Conduire validé</div></CardBody> </Card>
                  ) : null}
                  {okIBAN == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>IBAN validé</div></CardBody> </Card>
                  ) : null}
                  {okID == true ? (
                    <Card className={classes.cardOk}><CardBody className={classes.cardBodyOk}><div className={classes.textOk}>Pièce d'Identité validée</div></CardBody> </Card>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
        }


      </GridContainer>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import User from "components/Table/User";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as qs from 'qs'
import { onSnapshot, collection, updateDoc, doc, increment } from "firebase/firestore";
import { db, storage } from "firebase.jsx"; // course
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../../contexts/AuthContext"
import logo from "assets/img/anonymous.png";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useLocation } from 'react-router-dom';
// import { useSearchParams } from "react-router-dom";

const styles = {
  centerTextA: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    fontWeight: "bold",
    color: "rgba(4, 44, 92, 1)",
  },
  centerTextB: {

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(145, 145, 145, 1)",
  },
  selector: {
    "&:hover": {
      backgroundColor: "#DE335C",
      cursor: "pointer"
    },
    position: "relative",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    color: "white",
    height: "46px",
    fontWeight: "bold",
    marginLeft: "10%",
    fontSize: "18px",
    backgroundColor: "rgba(243, 182, 197, 1)",
    marginTop: "16px",
    marginBottom: "8px"
  },
  selectorActive: {
    "&:hover": {
      backgroundColor: "#DE335C",
      cursor: "pointer"
    },
    position: "relative",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    color: "white",
    height: "46px",
    fontWeight: "bold",
    marginLeft: "10%",
    fontSize: "18px",
    backgroundColor: "#DE335C",
    marginTop: "16px",
    marginBottom: "8px"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },








  courseList: {
    position: "relative",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    color: "white",
    height: "46px",
    fontWeight: "bold",
    marginLeft: "15%",
    fontSize: "18px",
    backgroundColor: "#00B0FF",
    marginTop: "16px",
    marginBottom: "8px"
  },
  caList: {
    position: "relative",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    color: "white",
    height: "46px",
    fontWeight: "bold",
    marginLeft: "15%",
    fontSize: "18px",
    backgroundColor: "#F0CD60",
    marginTop: "16px",
    marginBottom: "8px"
  },
  inputFormUser: {
    marginTop: "16px",
    marginBottom: "8px"
  },
  inputFormUserTop: {
    marginTop: "46px",
    marginBottom: "8px",
  },
  inputFormUserBottom: {
    marginTop: "32px",
    marginBottom: "8px",
    backgroundColor: "#1DC484",
    color: "white"
  }








































  // ,
  // logoImageFather: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  // logoImage: {
  //   width: "100px",
  //   height: "100px",
  //   cursor: "pointer"
  // },
  // img: {
  //   height: "100px",
  //   borderRadius: "50%",
  //   width: "100px",
  // },
  ,
  logoImageFather: {
    position: "relative",
    display: "block",
    width: "100%",
    height: "100%"
  },
  logoImage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",

  },
  img: {
    height: "100px",
    borderRadius: "50%",
    width: "100px",
  },




};

const useStyles = makeStyles(styles);

export default function Users(props) {
  const { currentUser } = useAuth()
  const [vehicule, setVehicule] = useState(0);
  const [fullName, setFullName] = useState(0);
  const [count, setCount] = useState(0);
  const [CA, setCA] = useState("0");
  const [imageUrl, setImage] = useState("anonymous");

  const [tel, setTel] = useState("");
  const [addr, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");

  useEffect(() =>
    onSnapshot(doc(db, `users`, currentUser.uid), async (snapshot) => {
      setVehicule(snapshot.data().vehicule)
      setFullName(`${snapshot.data().firstName} ${snapshot.data().lastName}`)
      setTel(snapshot.data().phone)
      setAdress(snapshot.data().adress)
      setCity(snapshot.data().city)
      setZip(snapshot.data().zipCode)

      setCount(snapshot.data().done.length)
      setCA(snapshot.data().CA)
      let _store = ref(storage, `users/${currentUser.uid}`)
      try {
        const url = await getDownloadURL(_store)
        console.log(url)
        setImage(url)
      } catch (error) {
        console.log(error)
      }
    }
    ),
    []
  );

  const setVelo = async (e) => {
    const user = doc(db, `users`, currentUser.uid)
    updateDoc(user, {
      vehicule: 1,
    });
  };
  const setScoot = async (e) => {
    const user = doc(db, `users`, currentUser.uid)
    updateDoc(user, {
      vehicule: 2,
    });
  };
  const setVoiture = async (e) => {
    const user = doc(db, `users`, currentUser.uid)
    updateDoc(user, {
      vehicule: 3,
    });
  };
  const setCamion = async (e) => {
    const user = doc(db, `users`, currentUser.uid)
    updateDoc(user, {
      vehicule: 4,
    });
  };

  const updateUser = async (e) => {
    const user = doc(db, `users`, currentUser.uid)
    if (tel != "" && tel != null && tel != undefined) {
      updateDoc(user, {
        hasPhone: true,
        adress: addr,
        city: city,
        zipCode: zip,
        phone: tel
      });
    } else {
      updateDoc(user, {
        hasPhone: false,
        adress: addr,
        city: city,
        zipCode: zip,
        phone: tel
      });
    }
  }


  const handleInputChangeID = async (e) => {
    if (e.target.files.length > 0) {
      const storageRef = ref(storage, `users/${currentUser.uid}`);
      uploadBytes(storageRef, e.target.files[0]).then(async (snapshot) => {
        console.log(snapshot);
        const user = doc(db, `users`, currentUser.uid)
        updateDoc(user, {
          images: increment(1)
        });
      }, (reason) => {
        console.log(reason)
      });
    }
  };
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={7}>
        <Card>
          <CardBody>
            <GridContainer>


              <GridItem xs={5} sm={5} md={5}>
                <input
                  accept="image/*"
                  onChange={handleInputChangeID}
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-file-id"
                  multiple
                  type="file"
                />
                <label className={classes.logoImageFather} htmlFor="raised-button-file-id">
                  <div className={classes.logoImage}>
                    <img src={(imageUrl != "anonymous") ? imageUrl : logo} alt="logo" className={classes.img} />
                  </div>
                </label>
              </GridItem>
              <GridItem xs={7} sm={7} md={7}>
                <h2 className={classes.centerTextA}>{fullName}</h2>
              </GridItem>






              <GridItem xs={6} sm={6} md={6}>
                <div className={classes.courseList}>{count} courses</div>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <div className={classes.caList}>{CA}€ C.A</div>
              </GridItem>



              <GridItem xs={12} sm={12} md={12}>
                <TextField fullWidth className={classes.inputFormUserTop} id="outlined-basic" label="Numéro de téléphone" variant="outlined" value={tel} onChange={(e) => setTel(e.target.value)} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField fullWidth className={classes.inputFormUser} id="outlined-basic" label="Adresse" variant="outlined" value={addr} onChange={(e) => setAdress(e.target.value)} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField fullWidth className={classes.inputFormUser} id="outlined-basic" label="Ville" variant="outlined" value={city} onChange={(e) => setCity(e.target.value)} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField fullWidth className={classes.inputFormUser} id="outlined-basic" label="Code Postal" variant="outlined" value={zip} onChange={(e) => setZip(e.target.value)} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button fullWidth className={classes.inputFormUserBottom} variant="contained" color="primary" onClick={updateUser}>Enregistrer les informations</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={5}>
        <Card>
          <CardBody>
            <h2 className={classes.centerTextA}>Mon mon de transport</h2>
            <h4 className={classes.centerTextB}>Séléctionner votre véhicule de livraison</h4>
            <div className={(vehicule == 1) ? classes.selectorActive : classes.selector} onClick={setVelo}>Je roule en vélo</div>
            <div className={(vehicule == 2) ? classes.selectorActive : classes.selector} onClick={setScoot}>Je roule en scooter</div>
            <div className={(vehicule == 3) ? classes.selectorActive : classes.selector} onClick={setVoiture}>Je roule en voiture</div>
            <div className={(vehicule == 4) ? classes.selectorActive : classes.selector} onClick={setCamion}>Je roule en camion</div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import { httpsCallable } from 'firebase/functions';
import { functions } from "firebase.jsx"; // add
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { onSnapshot, collection, where, updateDoc, doc } from "firebase/firestore";
import { db } from "firebase.jsx";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";


import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectAllRounded } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(styles);
export default function CoursesTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, tableType } = props;


    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});
    const [form, setValue] = React.useState({});
    const view = (parameter) => (event) => {
        event.preventDefault()
        console.log("parameter", tableData[parameter]);
        setOpen(true);
        setValue(tableData[parameter])
        setData(parameter)
    }


    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseOk = () => {
        updateDoc(doc(db, "users", form.id), {
            "type": form.type,
            "siret": form.siret ? form.siret : "",
            "lastName": form.lastName,
            "firstName": form.firstName,
            "email": form.email,
            "company": form.company,
            "adress": form.adress,
            "zipCode": form.zipCode,
            "city": form.city,
            "phone": form.phone,
        });
        console.log(form)
        setOpen(false);
    };

    const handleClick = (index) => (event) => {
        console.log(index);
        console.log(tableData[index].id);
        const deleteUser = httpsCallable(functions, 'removeUser');
        deleteUser({ id: tableData[index].id, type: "users" })
            .then((result) => {
                toast.success('Utilisateur supprimé', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((error) => {
                console.log(error)
                toast.error('Erreur lors de la suppression', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValue({
            ...form,
            [name]: value,
        });
    };
    const handleInputChangeSelect = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setValue({
            ...form,
            [name]: value,
        });
    };
    const handleSliderChange = (name) => (e, value) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formValues);
    };

    return (
        <div className={classes.tableResponsive}>
            <ToastContainer
                position="bottom-right"
                autoClose={false}
                hideProgressBar={true}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {data.id}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Attention, toute modification est permanante.
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            onChange={handleInputChange}
                            value={form.phone}
                            name="phone"
                            margin="dense"
                            id="phone"
                            label="Téléphone"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.adress}
                            name="adress"
                            margin="dense"
                            id="adress"
                            label="Adresse"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.zipCode}
                            name="zipCode"
                            margin="dense"
                            id="zipCode"
                            label="Code Postal"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.city}
                            name="city"
                            margin="dense"
                            id="city"
                            label="Ville"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.company}
                            name="company"
                            margin="dense"
                            id="company"
                            label="Société"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.email}
                            name="email"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.firstName}
                            name="firstName"
                            margin="dense"
                            id="firstName"
                            label="Prénom"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.lastName}
                            name="lastName"
                            margin="dense"
                            id="lastName"
                            label="Nom"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            onChange={handleInputChange}
                            value={form.siret}
                            name="siret"
                            margin="dense"
                            id="siret"
                            label="SIRET"
                            type="text"
                            fullWidth
                        />

                        <Select
                            onChange={handleInputChangeSelect}
                            className={classes.thaiTextFieldInputProps}
                            margin="dense"
                            fullWidth
                            variant="standard"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.type}
                            name="type"
                            label="Age"
                        >
                            <MenuItem value={"individual"}>Personnel</MenuItem>
                            <MenuItem value={"company"}>Professionnel</MenuItem>
                        </Select>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                    <Button onClick={handleCloseOk}>Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((data, index) => {
                        return (<TableRow key={index} className={classes.tableBodyRow}>

                            {
                                <TableCell key="id" className={classes.tableCell}>
                                    {data.id}
                                </TableCell>
                                // Object.keys(data).map(function (pro) {
                                //     if (pro == "firstName") {

                                //         return (<TableCell key={pro} className={classes.tableCell}>
                                //             {data[pro]}
                                //         </TableCell>)
                                //     }
                                // })
                            }
                            {
                                <TableCell key="firstName" className={classes.tableCell}>
                                    {data.firstName}
                                </TableCell>
                                // Object.keys(data).map(function (pro) {
                                //     if (pro == "firstName") {

                                //         return (<TableCell key={pro} className={classes.tableCell}>
                                //             {data[pro]}
                                //         </TableCell>)
                                //     }
                                // })
                            }

                            {

                                <TableCell key="lastName" className={classes.tableCell}>
                                    {data.lastName}
                                </TableCell>
                                // Object.keys(data).map(function (pro) {
                                //     if (pro == "lastName") {
                                //         return (<TableCell key={pro} className={classes.tableCell}>
                                //             {data[pro]}
                                //         </TableCell>)
                                //     }
                                // })
                            }
                            {
                                <TableCell key="phone" className={classes.tableCell}>
                                    {data.phone}
                                </TableCell>
                                //     Object.keys(data).map(function (pro) {
                                //     console.log(data.phone);
                                // if (pro == "phone") {
                                //             return (<TableCell key={pro} className={classes.tableCell}>
                                //     {data[pro]}
                                // </TableCell>)
                                //         }
                                //     })
                            }
                            {
                                <TableCell key="email" className={classes.tableCell}>
                                    {data.email}
                                </TableCell>
                            }
                            {
                                <TableCell key="adr" className={classes.tableCell}>
                                    {data.adress + " " + data.zipCode + " " + data.city}
                                </TableCell>
                            }
                            {
                                <TableCell key="courses" className={classes.tableCell}>
                                    {data.done.length + data.inprogress.length + data.scheduled.length}
                                </TableCell>
                            }
                            {
                                <TableCell key="type" className={classes.tableCell}>
                                    {(data.type == "individual") ? "Personnel" : "Professionnel"}
                                </TableCell>
                            }
                            <TableCell className={classes.tableCell}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Editer l'utilisateur"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={view(index)}
                                        aria-label="Editer"
                                        className={classes.tableActionButton}
                                    >
                                        <Edit
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Supprimer l'utilisateur"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        onClick={handleClick(index)}
                                        aria-label="Refuser"
                                        className={classes.tableActionButton}
                                    >
                                        <Close
                                            className={
                                                classes.tableActionButtonIcon +
                                                " " +
                                                classes.arrowForward
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>


                        </TableRow>)
                    })}

                </TableBody>
            </Table>
        </div >
    );
}

CoursesTable.defaultProps = {
    tableHeaderColor: "gray",
};

CoursesTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray",
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.any,
    tableType: PropTypes.string,
};
